import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  useLazyGetDoQuery,
  useLazyGetClientQuery,
  useLazyGetEmployeeQuery,
  useLazyGetProviderQuery,
  useLazyGetProviderRepreQuery,
  useLazyGetAgentQuery,
  useLazyGetLineaNavieraQuery,
  useLazyGetOpMapQuery,
  useLazyGetBkMapQuery,
  useLazyGetQtMapQuery,
  useLazyGetPoMapQuery,
  useLazyGetDoByClientQuery,
  useLazyGetProviderByClientQuery,
  useLazyGetReferenciaQuery,
  useLazyGetManifiestoQuery,
  useLazyGetComprobanteQuery,
  useLazyGetManifiestosByComprobanteQuery,
  useLazyGetDocTransRisQuery,
  useLazyGetInfoSalidaRisQuery
} from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { getEmpnitxx } from "../../libs/redux/slices/queueSlice/queueSlice";
import { getAreaSelected, getTypeSelected, getSerieSelected} from "../../libs/redux/slices/filterSlice/filterSlice";
import {
  useLazyGetTransporterQuery,
  useLazyGetCimaClientQuery,
  useLazyGetCimaProviderQuery,
  useLazyGetCimaDirectorProviderQuery,
  useLazyGetNumContratoQuery,
  useLazyGetUsrControllerQuery,
} from "../../libs/redux/slices/cimaxxxx/cimaxxxxApiSlice";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Autocomx = ({ objectMetadata, setJsonmetx, isMultiple, inputValue, bRequired, jsonmetx }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputSelected, setInputSelected] = useState([]);
  const [findOperations] = useLazyGetDoQuery();
  const [findCimaClients] = useLazyGetCimaClientQuery();
  const [findNumContrato] = useLazyGetNumContratoQuery();
  const [findUsrController] = useLazyGetUsrControllerQuery();
  const [findClients] = useLazyGetClientQuery();
  const [findEmployees] = useLazyGetEmployeeQuery();
  const [findTransporters] = useLazyGetTransporterQuery();
  const [findProviders] = useLazyGetProviderQuery();
  const [findCimaProviders] = useLazyGetCimaProviderQuery();
  const [findProvidersRepre] = useLazyGetProviderRepreQuery();
  const [findAgent] = useLazyGetAgentQuery();
  const [findLineaNaviera] = useLazyGetLineaNavieraQuery();
  const [findOperationsMap] = useLazyGetOpMapQuery();
  const [findBookingsMap] = useLazyGetBkMapQuery();
  const [findQuotationsMap] = useLazyGetQtMapQuery();
  const [findPositionsMap] = useLazyGetPoMapQuery();
  const [findDoByClients] = useLazyGetDoByClientQuery();
  const [findProviderByClient] = useLazyGetProviderByClientQuery();
  const findEmpresa = useSelector(getEmpnitxx);
  const areaSelec = useSelector(getAreaSelected);
  const typeSelec = useSelector(getTypeSelected);
  const serieSelected = useSelector(getSerieSelected);

  const [findProducto] = useLazyGetReferenciaQuery();
  const [findManifiesto] = useLazyGetManifiestoQuery();
  const [findComprobante] = useLazyGetComprobanteQuery();
  const [findCimaDirectionProviders] = useLazyGetCimaDirectorProviderQuery();
  const [findManifiestosByComprobante] = useLazyGetManifiestosByComprobanteQuery();
  const [findDocumentTransRis] = useLazyGetDocTransRisQuery();
  const [findInfoSalidaRis] = useLazyGetInfoSalidaRisQuery();

  useEffect(() => {
    optionByType();
    // eslint-disable-next-line
  }, [jsonmetx]);

  useEffect(() => {
    if (Object.keys(inputValue).length > 0 && inputValue[`${objectMetadata.METIDXXX}`] !== undefined && inputValue[`${objectMetadata.METIDXXX}`] !== "") {
      switch (objectMetadata.METOPXXX) {
        case "getDo":
        case "f_getController":
          if (typeof inputValue[`${objectMetadata.METIDXXX}`] === "object" && inputValue[`${objectMetadata.METIDXXX}`][0].id) {
            setInputSelected(inputValue[`${objectMetadata.METIDXXX}`]);
          }
          break;
        default:
          setInputSelected(inputValue[`${objectMetadata.METIDXXX}`]);
          break;
      }
    } else {
      setInputSelected([]);
    }
    // eslint-disable-next-line
  }, [inputValue, bRequired]);


  useEffect(() => {
    switch (objectMetadata.METOPXXX) {
      case "getClient":
      case "getAgent":
      case "getLineaNaviera":
      case "getProvider":
      case "getNumContrato":
      case "f_getController":
        if (inputValue[`${objectMetadata.METIDXXX}`]) {
          let COLUMNS = "";
          switch (objectMetadata.METOPXXX) {
            case "getClient":
              COLUMNS = "CLIIDXXX";
              break
            case "getAgent":
              COLUMNS = "AGEIDXXX";
              break
            case "getLineaNaviera":
              COLUMNS = "LINIDXXX";
              break
            case "getProvider":
              COLUMNS = "PIEIDXXX";
              break
            case "getNumContrato":
              COLUMNS = "NUMCONTR";
              break
            case "f_getController":
              COLUMNS = "USRCONTR";
              break
            default:
              break;
          }
          findFunction(inputValue[`${objectMetadata.METIDXXX}`], COLUMNS);
        }
        break;
        case "getDo":
          if (inputValue[`${objectMetadata.METIDXXX}`]) {
            let optionsValues = [];
            if (typeof inputValue[`${objectMetadata.METIDXXX}`] === "number") {
              optionsValues.push(inputValue[`${objectMetadata.METIDXXX}`].toString());
            } else {
              inputValue[`${objectMetadata.METIDXXX}`].forEach((option) => {
                optionsValues.push(option.id);
              });
            }
            setJsonmetx(objectMetadata.METIDXXX, optionsValues, "");
          }
          break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [])

  const optionByType = () => {
    switch (typeSelec) {
      case "619":
        if (objectMetadata.METIDXXX === "NUMANIFI") {
          (async () => {
            await findManifiestosByComprobante(jsonmetx.NUMCOMPR)
              .then((response) => {
                let formatData = [];
                let arrayManifiestos = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((manifiesto) => {
                    arrayManifiestos.push(manifiesto.maniidxx);
                    return { id: manifiesto.maniidxx, label: `${manifiesto.maniidxx} - ${manifiesto.manititu}` };
                  });
                  jsonmetx.NUMANIFI = arrayManifiestos;
                  setInputSelected(formatData);
                }
              })
          })();
        }
        break;
      default:
        break;
    }
  }

  let findFunction = () => { };
  switch (objectMetadata.METOPXXX) {
    case "getDo":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          if (jsonmetx.CLIIDXXX) {
            await findDoByClients({ value: value, CLIIDXXX: jsonmetx.CLIIDXXX })
              .then((response) => {
                let formatData = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((operation) => {
                    return { id: operation.id, label: operation.id };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
          } else {
            await findOperations(value)
              .then((response) => {
                let formatData = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((operation) => {
                    return { id: operation.DOCNROXX, label: operation.DOCNROXX };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
          }
        }
      };
      break;
    case "getClient":
      findFunction = async (value, FILTCAMP = "CLINOMXX") => {
        setIsLoading(true);
        if (value !== "") {
          const filtersJson = JSON.stringify([{"CIPO0024.REGESTXX":{"IN":["ACTIVO"]}},{"CIPO0024.ESTESPXX":{"NOTIN":["PROVISIONAL","RECHAZADO",""]}},]);
          if (FILTCAMP === "CLIIDXXX") {
            FILTCAMP = "CIPO0024.CLIIDXXX"
          }
          var filterEmpresa = `filtersJson=${encodeURIComponent(filtersJson)}&filters[${FILTCAMP}][LIKE]=${value}%`;
          //findCimaClients
          let formatData = [];
          await findCimaClients(filterEmpresa)
            .then((response) => {
              console.log(response);
              if (response?.data?.data) {
                console.log(response.data.data);
                formatData = response.data.data.map((client) => {
                  return { id: client.CLIIDXXX, label: `${client.CLINOMXX} - ${client.CLIIDXXX}` };
                });
              }
            })
            .catch(() => {
              console.log("Error in findCimaClients call")
            })
            .finally(async ()=>{
              if (FILTCAMP === "CIPO0024.CLIIDXXX") {
                FILTCAMP = "CLIIDXXX"
              }
              if(formatData.length>0){
                if (FILTCAMP === "CLIIDXXX") {
                  setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
                } else {
                  setOptions(formatData);
                }
                setIsLoading(false);
              } else if (findEmpresa !== '800251957') {
                switch (findEmpresa) {
                  case '830004237':
                    filterEmpresa = `&filters[MAPESTXX]=ACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
                    break;

                  case '830104929':
                    filterEmpresa = `&filters[CLILOGRE]=SI&filters[REGESTXX][<>]=INACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
                    break;

                  default:
                    filterEmpresa = `&filters[REGESTXX]=ACTIVO&filters[CLIESTCO]=ACTIVO&filters[${FILTCAMP}][LIKE]=${value}%`;
                    break;
                }

                await findClients(filterEmpresa)
                .then((response) => {
                  let formatData = [];
                  if (response?.data?.data?.data) {
                    formatData = response.data.data.data.map((client) => {
                      return { id: client.CLIIDXXX, label: `${client.CLINOMXX} - ${client.CLIIDXXX}` };
                    });
                  }
                  if (FILTCAMP === "CLIIDXXX") {
                    setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
                  } else {
                    setOptions(formatData);
                  }
                  setIsLoading(false);
                })
                .catch(() => {
                  setOptions([]);
                  setIsLoading(false);
                });
              }
            });
        }
      };
      break;
    case "getEmployee":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findEmployees(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((employee) => {
                  return { id: employee.USRIDXXX, label: employee.EMPNOCOX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getTransporter":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findTransporters(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((transporter) => {
                  return { id: transporter.id, label: `${transporter.id} - ${transporter.label}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getProvider":
      findFunction = async (value, FILTCAMP = "PIENOMXX") => {
        setIsLoading(true);
        if (value !== "") {
          var filterEmpresa = ``;
          switch (findEmpresa) {
            case '830004237':
              filterEmpresa = `&filters[MAPESTXX]=ACTIVO&filters[CLIAGMCX]=SI&filters[CLINOMXX][LIKE]=${value}%`;
              await findClients(filterEmpresa)
                .then((response) => {
                  let formatData = [];
                  if (response?.data?.data?.data) {
                    formatData = response.data.data.data.map((client) => {
                      return { id: client.CLIIDXXX, label: `${client.CLINOMXX} - ${client.CLIIDXXX}` };
                    });
                  }
                  setOptions(formatData);
                  setIsLoading(false);
                })
                .catch(() => {
                  setOptions([]);
                  setIsLoading(false);
                });
              break;
            case '900698414':
            case '901374991':
            case '860079024':
              filterEmpresa = `&filters[REGESTXX]=ACTIVO&filters[PROITDES][LIKE]=%${value}%`;
              await findProvidersRepre(filterEmpresa)
                .then((response) => {
                  let formatData = [];
                  if (response?.data?.data?.data) {
                    formatData = response.data.data.data.map((provider) => {
                      return { id: provider.PROITIDX, label: `${provider.PROITIDX} - ${provider.PROITDES}` };
                    });
                  }
                  setOptions(formatData);
                  setIsLoading(false);
                })
                .catch(() => {
                  setOptions([]);
                  setIsLoading(false);
                });

              break;
            case '800251957':
              if (FILTCAMP === "PIEIDXXX") {
                FILTCAMP = "IDPROXXX"
              } else {
                FILTCAMP = "PRORAZXX"
              }
              filterEmpresa = `filters[CIPO0050.REGESTXX]=ACTIVO&filters[CIPO0050.${FILTCAMP}][LIKE]=${value}&filters[CIPO0050.CLIIDXXX][LIKE]=${jsonmetx.CLIIDXXX}`;
              await findCimaProviders(filterEmpresa)
                .then((response) => {
                  if (FILTCAMP === "IDPROXXX") {
                    FILTCAMP = "PIEIDXXX"
                  }
                  let formatData = [];
                  if (response?.data?.data) {
                    formatData = response.data.data.map((provider) => {
                      return { id: provider.IDPROXXX, label: `${provider.IDPROXXX} - ${provider.PRORAZXX}`, procdbax: provider.PROCDBAX };
                    });
                  }
                  if (FILTCAMP === "PIEIDXXX") {
                    setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
                  } else {
                    setOptions(formatData);
                  }
                  setIsLoading(false);
                })
                .catch(() => {
                  setOptions([]);
                  setIsLoading(false);
                });
                break;
            default:
              let bImpoExp = false;
              switch (areaSelec) {
                case "115":
                  filterEmpresa = `&filters[REGESTXX]=ACTIVO&filters[${FILTCAMP}][LIKE]=${value}`;
                  break
                case "100":
                case "101":
                  if (!jsonmetx.CLIIDXXX) {
                    setIsLoading(false);
                    return;
                  } else {
                    bImpoExp = true;
                  }
                  break
                default:
                  filterEmpresa = `&filters[REGESTXX]=ACTIVO&filters[PIEAABBY]=SI&filters[${FILTCAMP}][LIKE]=${value}`;
                  break
              }
              if (bImpoExp) {
                filterEmpresa = `&filters[CIPO0050.REGESTXX]=ACTIVO&filters[CIPO0050.CLIIDXXX][LIKE]=${jsonmetx.CLIIDXXX}&filters[CIPO0050.PRORAZXX][LIKE]=${value}`;
                await findCimaProviders(filterEmpresa)
                  .then((response) => {
                    console.log(response);
                    let formatData = [];
                    if (response?.data?.data) {
                      formatData = response.data.data.map((provider) => {
                        return { id: provider.IDPROXXX, label: `${provider.IDPROXXX} - ${provider.PRORAZXX}`, procdbax: provider.PROCDBAX };
                      });
                    }
                    if (FILTCAMP === "PIEIDXXX") {
                      setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
                    } else {
                      setOptions(formatData);
                    }
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setOptions([]);
                    setIsLoading(false);
                  });
              } else {
                await findProviders(filterEmpresa)
                  .then((response) => {
                    let formatData = [];
                    if (response?.data?.data?.data) {
                      formatData = response.data.data.data.map((provider) => {
                        return { id: provider.PIEIDXXX, label: `${provider.PIEIDXXX} - ${provider.PIENOMXX}` };
                      });
                    }
                    if (FILTCAMP === "PIEIDXXX") {
                      setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
                    } else {
                      setOptions(formatData);
                    }
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setOptions([]);
                    setIsLoading(false);
                  });
              }
              break;
          }
        }
      };
      break;
    case "getAgent":
      findFunction = async (value, FILTCAMP = "AGEDESXX") => {
        setIsLoading(true);
        if (value !== "") {
          let filterAgen = `&filters[REGESTXX]=ACTIVO&filters[${FILTCAMP}][LIKE]=${value}%`;
          await findAgent(filterAgen)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((Agent) => {
                  return { id: Agent.AGEIDXXX, label: Agent.AGEDESXX };
                });
              }
              if (FILTCAMP === "AGEIDXXX") {
                setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
              } else {
                setOptions(formatData);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getLineaNaviera":
      findFunction = async (value, FILTCAMP = "LINDESXX") => {
        let filterlinea = `&filters[REGESTXX]=ACTIVO&filters[${FILTCAMP}][LIKE]=${value}%`;
        setIsLoading(true);
        if (value !== "") {
          await findLineaNaviera(filterlinea)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((Navier) => {
                  return { id: Navier.LINIDXXX, label: Navier.LINDESXX };
                });
              }
              if (FILTCAMP === "LINIDXXX") {
                setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
              } else {
                setOptions(formatData);
              }
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getOpMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findOperationsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OPOPEXXX, label: operation.OPOPEXXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getBkMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findBookingsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OSNOMRES, label: operation.OSNOMRES };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getQtMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findQuotationsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.QUONUMXX, label: operation.QUONUMXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getPoMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findPositionsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OPPOSXXX, label: operation.OPPOSXXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getReferencia":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          if (jsonmetx.CLIIDXXX) {
            await findProducto({ value: value, CLIIDXXX: jsonmetx.CLIIDXXX })
              .then((response) => {
                let formatData = [];
                if (response?.data?.data?.data) {
                  formatData = response.data.data.data.map((producto) => {
                    return { id: producto.prorefxx, label: `${producto.prorefxx} - ${producto.prodesxx}` };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
          }
        }
      };
      break;
    case "getManifiesto":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findManifiesto(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((manifiesto) => {
                  return { id: manifiesto.maniidxx, label: `${manifiesto.maniidxx} - ${manifiesto.manititu}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getComprobante":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findComprobante(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((comprobante) => {
                  return { id: comprobante.manicomp, label: `${comprobante.manicomp}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
      case "getDireccionProveedor":
        findFunction = async (value) => {
          setIsLoading(true);
          if (value !== "") {
            console.log(jsonmetx);
            var filterEmpresa = ``;
              filterEmpresa = `filters[CLIIDXXX]=${jsonmetx.CLIIDXXX}&filters[IDPROXXX]=${jsonmetx.PROIDXXX}&filters[PRODIRXX][LIKE]=${value}`;
              await findCimaDirectionProviders(filterEmpresa)
                .then((response) => {
                  let formatData = [];
                  if (response?.data?.data) {
                    formatData = response.data.data.map((direction) => {
                      return { id: `${direction.PRODIRXX} ,-, ${direction.CLIPROCB} ,-, ${direction.APLVECIX} ,-, ${direction.PIEIDXXX}`, label: `${direction.PRODIRXX}` };
                    });
                  }
                  setOptions(formatData);
                  setIsLoading(false);
                })
                .catch(() => {
                  setOptions([]);
                  setIsLoading(false);
                });

          }
        };
        break;
    case "getNumContrato":
      findFunction = async (value, FILTCAMP = "CLIPRONC") => {
        setIsLoading(true);
        if (value !== "") {
          var filterNumContrato = `filters[CIPO0157.REGESTXX]=ACTIVO&filters[${FILTCAMP}][LIKE]=${value}%`;
          filterNumContrato += `&filters[CIPO0157.CLIIDXXX][LIKE]=${jsonmetx.CLIIDXXX}&filters[CIPO0157.IDPROXXX]=${jsonmetx.PROIDXXX}`;
          let formatData = [];
          await findNumContrato(filterNumContrato)
            .then((response) => {
              console.log(response);
              if (response?.data?.data) {
                console.log(response.data.data);
                formatData = response.data.data.map((numContrato) => {
                  return { id: numContrato.CLIPRONC, label: numContrato.CLIPRONC };
                });
              }
            })
            .catch(() => {
              console.log("Error in findCimaClients call")
            })

            .finally(async ()=>{
              if (FILTCAMP === "NUMCONTR") {
                setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
              } else {
                setOptions(formatData);
              }
              setIsLoading(false);
            });
        }
      };
      break;
    case "getDocTrans":
      const handleResponse = (response, key1, key2) => {
        let formatData = [];
        if (response?.data?.data?.data) {
          formatData = response.data.data.data.map((infodoc) => {
            let labelDoc = infodoc[key1];
            if (infodoc[key2] !== "") {
              labelDoc = labelDoc + "-" + infodoc[key2];
            }
            return { id: labelDoc, label: labelDoc };
          });
        }
        return formatData;
      };

      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          const fetchData = serieSelected === "192" ? findDocumentTransRis : findInfoSalidaRis;
          const key1 = serieSelected === "192" ? "dtrdtrxx" : "saldtrxx";
          const key2 = serieSelected === "192" ? "dtridxxx" : "salidxxx";

          await fetchData(value)
            .then((response) => {
              const formatData = handleResponse(response, key1, key2);
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "f_getController":
      findFunction = async (value, FILTCAMP = "COTUSRXX") => {
        setIsLoading(true);
        if (value !== "") {
          var filterController = `filters[CIMS0001.USRNOMXX][LIKE]=${value}%&filters[CIMS0072.MODIDXXX]=114&filters[CIMS0072.PROIDXXX]=100`;
          let formatData = [];
          await findUsrController({
            filter: filterController,
            CLIIDXXX: jsonmetx.CLIIDXXX?.trim() ? jsonmetx.CLIIDXXX : null,
            APLALLXX: jsonmetx?.USERDIFE === "SI"
          }).then((response) => {
              if (response?.data?.data) {
                formatData = response.data.data.map((descController) => {
                  return {
                    id:       descController.value,
                    label:    descController.label,
                    COTIDXXX: descController.COTIDXXX,
                    COTDESXX: descController.COTDESXX,
                  };
                });
              }
            })
            .catch(() => {
              console.log("Error in findCimaClients call")
            })

            .finally(async ()=>{
              if (FILTCAMP === "USRCONTR") {
                setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
              } else {
                setOptions(formatData);
              }
              setIsLoading(false);
            });
        }
      };
      break;
    default:
      break;
  }
  return (
    <AsyncTypeahead
      size="sm"
      multiple={isMultiple}
      id={objectMetadata.METIDXXX}
      inputProps={{
        name: objectMetadata.METIDXXX,
        id: objectMetadata.METIDXXX,
      }}
      filterBy={["id", "label"]}
      placeholder="Buscar..."
      searchText="Buscando..."
      emptyLabel="Sin registros"
      promptText="Escriba para buscar"
      minLength={3}
      isLoading={isLoading}
      options={options}
      selected={inputSelected}
      onChange={(optionsSelected) => {
        const key = objectMetadata.METIDXXX;
        setInputSelected(optionsSelected);
        if (optionsSelected.length > 0 && !isMultiple) {
          setJsonmetx(key, optionsSelected[0].id, optionsSelected[0].label);
          if(key === "PROIDXXX"){
            setJsonmetx("CAPDAFAC", optionsSelected.length > 0 && optionsSelected[0].procdbax ? optionsSelected[0].procdbax : "");
          }
          if(key === "USRCONTR"){
            setJsonmetx(key, optionsSelected[0], optionsSelected[0].label);
          }
        } else if (optionsSelected.length > 0 && isMultiple) {
          let optionsValues = [];
          optionsSelected.forEach((option) => {
            optionsValues.push(option.id);
          });
          setJsonmetx(key, optionsValues, "");
        } else {
          setJsonmetx(key, "");
        }
      }}
      onSearch={findFunction}
      useCache={(objectMetadata.METIDXXX === "PROIDXXX" || objectMetadata.METIDXXX === "USRCONTR") ? false : true}
    />
  );
};

export default Autocomx;
